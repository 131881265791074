import { Link, graphql, navigate } from "gatsby"

import Helmet from "react-helmet"
import Image from "../components/image"
import Navigation from "../components/navigation"
import React from "react"

export default ({ data }) => {
  const post = data.googleDocument
  const minutes = Math.ceil(post.words / 275)
  let rtf
  if (typeof window !== "undefined") {
    rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" })
  }
  const date = new Date(post.modifiedTime)
  const now = new Date()
  const diffTime = Math.abs(now - date)
  const diffDays = +(diffTime / (1000 * 60 * 60 * 24)).toFixed()
  return (
    <>
      <Navigation />
      <Helmet title={post.title} />
      <button
        style={{
          height: "max-content",
          fontSize: "30px",
          marginRight: "1em",
          position: "sticky",
          top: "32px",
          zIndex: "1",
        }}
        onClick={() => {
          if (!window.history.state) return navigate("/")
          window.history.back()
        }}
      >
        ←
      </button>
      <main>
        <h1>{post.title}</h1>
        <div
          style={{
            marginBottom: "1em",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{`${Array.from({ length: Math.ceil(minutes / 5) })
            .map(_ => "☕")
            .join("")} ${minutes} ${
            minutes === 1 ? "minute" : "minutes"
          } read`}</span>
          <span>
            Last updated
            {rtf && diffDays <= 30
              ? " " + rtf.format(-diffDays, "days")
              : " " + date.toLocaleDateString()}
          </span>
        </div>

        {post.content.map((paragraph, index) => {
          return (
            <p>
              {paragraph.map((paragraphElement, innerIndex) => {
                let component = null
                let style = undefined
                let hasStyles = false
                if (paragraphElement.image)
                  return <Image key={index} src={paragraphElement.image} />
                if (paragraphElement.text) {
                  component = paragraphElement.text

                  const {
                    foregroundColor,
                    bold,
                    italic,
                    underline,
                    link,
                  } = paragraphElement.style
                  if (foregroundColor) {
                    const { red, blue, green } = foregroundColor.color.rgbColor
                    const decimalToBinary = decimal =>
                      Math.floor(decimal >= 1.0 ? 255 : decimal * 256.0)
                    style = {
                      color: `rgb(${red ? decimalToBinary(red) : 0}, ${
                        green ? decimalToBinary(green) : 0
                      }, ${blue ? decimalToBinary(blue) : 0})`,
                    }
                  }
                  if (bold) {
                    component = (
                      <b
                        style={
                          style && !(italic || underline || link)
                            ? style
                            : undefined
                        }
                      >
                        {component}
                      </b>
                    )
                    hasStyles = true
                  }
                  if (italic) {
                    component = (
                      <i
                        style={
                          style && !(underline || link) ? style : undefined
                        }
                      >
                        {component}
                      </i>
                    )
                    hasStyles = true
                  }
                  if (underline) {
                    component = (
                      <u style={style && !link ? style : undefined}>
                        {component}
                      </u>
                    )
                    hasStyles = true
                  }
                  if (link) {
                    if (link.url.startsWith(`https://pkuhlmann.dev`)) {
                      component = (
                        <Link
                          to={link.url.substring(
                            `https://pkuhlmann.dev`.length
                          )}
                          style={style}
                        >
                          {component}
                        </Link>
                      )
                    } else {
                      component = (
                        <a href={link.url} style={style}>
                          {component}
                        </a>
                      )
                    }

                    hasStyles = true
                  }

                  if (!hasStyles && style)
                    component = <span style={style}>{component}</span>
                  var Component = () => component
                }
                return <Component key={index + innerIndex} />
              })}
            </p>
          )
        })}
      </main>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    googleDocument(slug: { eq: $slug }) {
      title
      words
      modifiedTime
      content {
        image
        text
        style {
          bold
          italic
          underline
          link {
            url
          }
          foregroundColor {
            color {
              rgbColor {
                blue
                green
                red
              }
            }
          }
        }
      }
    }
  }
`
