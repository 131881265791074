import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"

const Image = ({ src, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () =>
      data.allFile.edges.find(({ node }) => node.relativePath.includes(src)),
    [data, src]
  )

  return src ? (
    <Img
      fluid={match ? match.node.childImageSharp.fluid : undefined}
      {...props}
    />
  ) : null
}

export default Image
